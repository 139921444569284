import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducer';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { SetLangAction } from './app.actions';

import { IToastMsg } from './shared/components/toast-msg/interfaces/toast-msg.interface';
import { ToastMsgService } from './shared/components/toast-msg/toast-msg.service';
import { ToastMensajeType } from './shared/components/toast-msg/enum/toast-type';
import { ActivarLoadingAction, DesactivarLoadingAction } from './shared/components/loading/store/loading.actions';

@Component({
  selector: 'aig-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {


  tema: string;

  private toastTypes = ToastMensajeType;

  constructor(private store: Store<AppState>, private readonly translate: TranslateService, private router: Router, private toastSrv: ToastMsgService) {
    
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.store.dispatch(new ActivarLoadingAction());
      }else if(event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel){
        this.store.dispatch(new DesactivarLoadingAction());
      }
    })

    this.store.select('lang').subscribe(lang => {
      this.translate.setDefaultLang('es');
      this.translate.use('es');
    });

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'es');
    }
    this.store.dispatch(new SetLangAction(localStorage.getItem('lang')));
  }

  ngAfterViewInit(): void {
    // const mensaje: IToastMsg = {
    //   position:'bmiddle',
    //   type: this.toastTypes.SUCCESS,
    //   heading: '',
    //   message: 'Your RMA has been created! We will sent you a confirmation email shortly',
    //   showClose: true,
    //   visible: true,
    //   time: 10 
    // };
    // this.toastSrv.setToast(mensaje);
  }

}


