import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, throwIfEmpty } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, private translateService: TranslateService, private store: Store<AppState>) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      /* this.store.dispatch(new DesactivarLoadingAction())

        switch (error.status) {
          case 500:
            this._as.setAlert(AlertType.DANGER, "Ha ocurrido un error", "SALIR", () => {this.router.navigate(['/login'])});
            break;
          case 401:
              this._as.setAlert(AlertType.INFO, "Su sessión ha caducado", "SALIR", () => {this.router.navigate(['/login'])});
            break;
          case 404:
              this._as.setAlert(AlertType.DANGER, "Ha ocurrido un error", "SALIR", () => {this.router.navigate(['/login'])});
            break;

          default:
              this._as.setAlert(AlertType.DANGER, "Ha ocurrido un error", "SALIR", () => {this.router.navigate(['/login'])});
            break;
        } */
      return throwError(error);
    }));
  }

  signOut() {
    sessionStorage.clear();
    this.router.navigate(["/login"]);
  }

}
