import { Action } from '@ngrx/store';

export const SET_LANG = '[UI LANG] Cargando...';
export class SetLangAction implements Action {
    readonly type = SET_LANG;

    constructor(public lang: string) { }
}

export type LANG_ACTIONS = SetLangAction;
