<ng-container>
  <div class="abc-selectfield c-input u-ds-flex u-dir-col">
    <span class="u-fs-14 u-fw-600 u-color-primary1"> {{ label }} *</span>
    <div class="abc-selectfield__field u-pos-relative c-input__field u-ds-flex u-align-center u-ps-1 "
      [ngClass]="disabled ? 'c-input__field--disabled' : ''" #selectInput>

      <div class="u-size-per-100 u-ellipsis--line" (click)="activateOptions()">
        <p class="u-fs-12">
          {{ activeValue?.label }}
        </p>
        <div *ngIf="(activeValue.value); then iconChev else iconChev">
        </div>
        <!-- <div class="c-input__icon u-pos-absolute u-end-0 u-top-0">
        <i class="icon-Chevron-Down"></i>
      </div> -->
      </div>

      <ng-select class="abc-selectfield__options u-shadow-box" [ngClass]="display && !disabled ? '' : 'u-ds-none'">
        <div *ngIf="_options">
          <div *ngFor="let option of _options" class="abc-selectfield__option p-flexcs" (click)="setNewValue(option)">
            {{ option.label }}
          </div>
        </div>
      </ng-select> <!-- end of abc-selectfield__options -->

    </div> <!-- end of c-input__field -->
  </div>
</ng-container>


<ng-template #iconClose>
  <div class="c-input__icon u-pos-absolute" click-stop-propagation (click)="resetValue()">
    <i class="icon-Close1"></i>
  </div>
</ng-template>

<ng-template #iconChev>
  <div class="c-input__icon u-pos-absolute">
    <i class="icon-Chevron-Down"></i>
  </div>
</ng-template>