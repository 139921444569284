import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { IToastMsg } from './interfaces/toast-msg.interface';
import { ToastMsgService } from './toast-msg.service';

@Component({
  selector: 'icp-toast-msg',
  templateUrl: './toast-msg.component.html',
  styleUrls: ['./toast-msg.component.scss']
})
export class ToastMsgComponent implements OnInit {


  public toast: IToastMsg = {
    position:'bmiddle',
    type:'',
    heading:'',
    message:'',
    showClose: false,
    visible: false,
    time: 3000
  };
  @ViewChild('toastRef') domToast: ElementRef;
 

  
  constructor(public toastService: ToastMsgService, public renderer: Renderer2) { 
    this.toastService.onToast().subscribe((resToasts: IToastMsg) => {
      this.toast = resToasts;
      this.show();
      if(resToasts.time > 0){
          this.hide();
      }
    });
  }

  ngOnInit(): void {
    
  }

  public show(): void {
    this.renderer.addClass(this.domToast.nativeElement, this.toast.position);
    this.renderer.addClass(this.domToast.nativeElement, this.toast.type);
    this.renderer.removeClass(this.domToast.nativeElement, 'closed');
  }
  
  public hide(closeButton: boolean = false): void {

    if (closeButton) {
      this.changeClassesOnClose(50);
    } else {
      this.changeClassesOnClose(this.toast.time);
    }

  
    this.toast.visible = false;
  }


  private changeClassesOnClose(time: number): void {

    setTimeout(() => {
      this.renderer.addClass(this.domToast.nativeElement, 'closed');
      setTimeout(() => {
        this.renderer.removeClass(this.domToast.nativeElement, this.toast.type);
        this.renderer.removeClass(this.domToast.nativeElement, this.toast.position);
      }, 1000);
    }, time);

  }
}
