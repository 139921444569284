import { ACTIVAR_LOADING, DESACTIVAR_LOADING, LOADING_ACTIONS } from './loading.actions';

export interface LoadingState {
  loading: boolean;
}

const initState: LoadingState = {
  loading: false
};

export function loadingReducer( state = initState, action: LOADING_ACTIONS ): LoadingState {

    switch ( action.type ) {
        case ACTIVAR_LOADING:
            return {
              loading: true
            };

        case DESACTIVAR_LOADING:
            return {
              loading: false
            };

        default:
            return state;
    }
}
