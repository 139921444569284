import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { IUser } from '../auth/IUser';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    // user: IUser;
    // constructor(private store: Store<AppState>) {
    //   this.store.select('usuario').subscribe(usuario => {
    //     if(usuario.usuario) {
    //       this.user = usuario.usuario;
    //     }
    //   })
    // }
    token: string;

    constructor() {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      // if (!request.headers.has('skipBearer') && this.user != undefined) {
      //   request = request.clone({
      //     setHeaders: {
      //       'Authorization': `Bearer ${this.user.TOKEN}`,
      //       'ih': `${this.user.IH}`,
      //           'Access-Control-Allow-Origin': '*',
      //           'Pragma': 'no-cache',
      //           'Cache-Control': 'no-cache, no-store, must-revalidate',
      //           'Access-Control-Request-Headers': 'content-type,skipbearer'
      //       }
            
      //   });
        
      // } 

      if (!request.headers.has('skipBearer')) {
        this.token = window.sessionStorage["auth-token"];
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            "withCredentials":'true',
            'Access-Control-Allow-Origin': '*',
            'Pragma': 'no-cache',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'IH': 'H4sIAAAAAAACCqpWykxRsjIy1lFKLlGyyivNydFRyklXslJKLdZ1DVbSUcpPA3K0DQytDAyAvIxiIC882NHJ0c/ZUakWAAAA//8='
          } 
        });
      }
      else {
        request = request.clone({
          setHeaders: {
              "withCredentials":'true',
              'Access-Control-Allow-Origin': '*',
              'Pragma': 'no-cache',
              'Cache-Control': 'no-cache, no-store, must-revalidate'
          }
        });
      }
      return next.handle(request);

    }

}
