<div class="aig-footer u-bg-secondary2 u-color-white u-size-per-100 p-flexcc">
  <div class="o-container-lg u-size-inherit">
    <div class="row u-mx-0">
      <div class="aig-footer__col col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <a href="https://www.aig.com.es/contacto" class="aig-footer__link">Contacto</a>
        <a href="https://www.aig.com.es/corporate" class="aig-footer__link">Conoce AIG</a>
      </div>
      <div class="aig-footer__col col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <a href="https://www.aig.com.es/siniestros" class="aig-footer__link">Siniestros</a>
        <a href="https://www.aig.com.es/home--seguros-de-aig-espana/omespain/retribuciones-a-intermediarios" class="aig-footer__link">Retribuciones a intermediarios</a>
      </div>
      <div class="aig-footer__col col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <a href="https://www.aig.com/careers" class="aig-footer__link">Trabaja con AIG</a>
      </div>
      <div class="aig-footer__col col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <p>Síguenos en</p>
        <a class="aig-footer__rrss u-mt-2" href="https://www.facebook.com/AIGSegurosSpain" target="_blank">
          <img class="u-size-per-100" src="../../../../assets/images/icons/icon-Facebook.png" alt="icon-Facebook">
        </a>
      </div>

      <div class="aig-footer__col col-12 u-mt-aig">
        <p class="u-mb-2">
          España | <a href="https://aig.com/worldwide" class="aig-footer__link">  Worldwide &#62; </a>
        </p>
        <img src="../../../../assets/images/cookiepreferences.png" alt="cookiepreferences">
      </div>

      <div class="aig-footer__col col-12 u-my-aig">
        <p>
          <a class="aig-footer__link" href="https://www.aig.com.es/condiciones-de-uso"> Condiciones de Uso</a>
          |
          <a class="aig-footer__link" href="https://www.aig.com.es/politica-de-privacidad">Política de Privacidad</a>
        </p>
        <p class="u-text-center@xs">
          Copyright © 2020 American International Group, Inc. All rights reserved.
        </p>
      </div>

      <div class="aig-footer__col col-12 u-mt-aig">
        <p class="u-text-center@xs">
          AIG Europe S.A., es una compañía aseguradora con número R.C.S. de Luxemburgo B 218806.
          AIG Europe S.A., tiene su sede central en el número 35D de Avenue John F. Kennedy,
          L-1855, Luxemburgo, <a class="aig-footer__link" href="http://www.aig.lu/">http://www.aig.lu/</a> . AIG Europe, S.A.
          está autorizada por el Ministère des Finances de Luxemburgo y supervisado por el Commissariat aux Assurances,
          cuya dirección es 7, boulevard Joseph II, L-1840 Luxemburgo, GD de Luxemburgo, Tel.: (+352) 22 69 11 - 1,
          <a class="aig-footer__link" href="mailto:caa@caa.lu">caa@caa.lu</a>, <a class="aig-footer__link" href="http://www.caa.lu/"> http://www.caa.lu/</a>.
          AIG Europe S.A. Sucursal en España tiene su domicilio en Paseo de la Castellana 216, 28046, Madrid y Número de Identificación Fiscal W0186206I. En materia de conducta de mercado, la Sucursal está regulada por Dirección General de Seguros y Fondos de Pensiones.
          Podrá encontrar los datos de contacto de la Dirección General de Seguros y Fondos de Pensiones en este enlace:
          <a class="aig-footer__link" href="http://www.dgsfp.mineco.es"> http://www.dgsfp.mineco.es </a>.
        </p>

        <p class="u-text-center@xs u-mt-3">
          MEDIADOR DEL SEGURO: ABANCA MEDIACIÓN, OPERADOR DE BANCA SEGUROS VINCULADO, S.L,
          operador de banca-seguros vinculado a AIG, con domicilio social en Avenida de la Marina 1,
          4ª planta de A Coruña, y provista de CIF B-70049630, inscrita en el Registro Administrativo Especial de Mediadores de Seguros de la Dirección General de Seguros y Fondos de Pensiones con el nº OV-0044.
          Con Seguro de Responsabilidad Civil, Capacidad Financiera exigidos por la normativa de distribución de seguros vigente.
          Consulta resto de aseguradoras con las que el mediador tiene concertado un contrato de agencia  en
          <a class="aig-footer__link" target="_blank" href="https://www.abanca.com/es/">abanca.com</a>.
        </p>
      </div>
      
    </div>
  </div>
</div>