import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {  TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {  HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducers, metaReducers, arrEffects } from "./app.reducer";
import { WINDOW_PROVIDERS } from "./core/services/window.service";
import { AuthGuardService } from "./core/auth/auth-guard.service";
import { environment } from "src/environments/environment";
import { HttpErrorInterceptor } from "./core/services/httpError.interceptor";
import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LayoutModule } from "./core/layout/layout.module";
import { SelectfieldModule } from "./shared/components/selectfield/selectfield.module";
import { ToastMsgModule } from "./shared/components/toast-msg/toast-msg.module";
import { LoadingModule } from "./shared/components/loading/loading.module";
import {
  RecaptchaModule,
} from "ng-recaptcha";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingModule,
    SelectfieldModule,
    ToastMsgModule,
    TranslateModule.forRoot(),
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot(arrEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    FontAwesomeModule,
    LayoutModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    WINDOW_PROVIDERS,
    AuthGuardService,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

