export function AutoUnsubscribe(noAutoUnsubscribe = []) {
  return function(constructor) {
    const onDestroy = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function() {
      travelProperties(this, noAutoUnsubscribe);
      onDestroy &&
        typeof onDestroy === "function" &&
        onDestroy.apply(this, arguments);
    };
  };
}

function travelProperties(properties, noAutoUnsubscribe) {
  for (const prop in properties) {
    const property = properties[prop];
    if (!noAutoUnsubscribe.includes(prop)) {
      if (property && typeof property.unsubscribe === "function") {
        property.unsubscribe();
      } else if (property && typeof property === "function") {
        travelProperties(prop, noAutoUnsubscribe);
      }
    }
  }
}
