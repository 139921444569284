<div #toastRef class="abc-toast c-toast closed" >
  <div class="row u-size-per-100 u-pt-2">

    <div class="col-1 u-ds-flex u-justify-end u-pe-2 u-ps-3">
      <i class="icon-Check-Filled u-fs-16 u-color-success" *ngIf="toast.type === 'success'" ></i>
      <i class="icon-Close-Filled u-fs-16 u-color-warning1" *ngIf="toast.type === 'error'" ></i>
    </div>

    <div class="col-10 u-ps-3">
      <div class="row u-mx-0">
        <!-- <div class="col-10 p-0">
          <span class="u-fs-16 u-fw-600"> {{ toast.heading }} </span>
        </div> -->
        <div class="col-2 p-0 u-ds-flex u-justify-end" *ngIf="toast.showClose">
          <i class="icon-Close u-cursor-pointer" (click)="hide(true)" ></i>
        </div>
      </div>
      <div class="row u-ps-3">
        <p class="u-fs-14 u-size-per-100">
          {{ toast.message }}
        </p>
      </div>
    </div>
  </div>
</div>
