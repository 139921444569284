import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "aig-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isCollapse = true;
  toggleState() {
    let foo = this.isCollapse
    this.isCollapse = foo === false ? true : false;
  }
  
  icono_idioma: string;
  presentMenu: boolean = false;
  esProduccion = environment.production;


  public idioma: boolean = false;
  public abrir: boolean = false;

  @ViewChild("menu") menu: ElementRef;
  @ViewChild("btnMenu") btnMenu: ElementRef;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private router: Router
  ) {
    this.store.select("lang").subscribe((lang) => {
      this.translate.setDefaultLang(lang.lang);
      this.translate.use(lang.lang);
      this.icono_idioma = lang.lang;
    });



    this.store.select("lang").subscribe((lang) => {
      this.translate.setDefaultLang(lang.lang);
      this.translate.use(lang.lang);
    });
  }

  ngOnInit() { }

  public goToMain (): void {
    this.router.navigateByUrl('/');
  }

  public goToLogin(): void {
    this.router.navigate(['/auth', 'login']);
  }

}
