<header class="aig-header">
  <div class="aig-header__container u-py-2">
    <div class="p-flexcb">
      <div class="aig-header__block u-gap-8">
        <a class="aig-header__logo" routerLink="/" >
          <img class="u-size-per-100 a" src="./assets/images/logo/logo.svg" alt="logo_aig" loading="lazy" />
        </a>
        <img class="aig-header__logo" src="./assets/images/logo/abanca-logo.jpg" alt="logo_abanca" loading="lazy">
      </div>
    </div>
  </div>
</header>