import { Component, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';


@Component({
  selector: 'aig-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit  {

  @ViewChild('header') header;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let header = document.getElementById('header');

        header.scrollIntoView();
      }
    })
  }

  ngOnInit(): void {
  }
}
