import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  Optional,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ISelectField } from './selectfield.interface';

@Component({
  selector: 'aig-selectfield',
  templateUrl: './selectfield.component.html',
  styleUrls: ['./selectfield.component.scss']
})
export class SelectfieldComponent implements OnInit, AfterViewChecked {

  _options: Array<ISelectField>
  @Input() set options(value: Array<ISelectField>) {
    if (value) {
      this._options = value;
      this.doActiveSelectedOption();
      this.selectIfOptionIsOnlyOne();
    }
  }


  @Input() label: string;
  public _selectFieldFormControlName: string;
  @Input() set selectFieldFormControlName(value: string) {

    if (value) {
      this._selectFieldFormControlName = value;
      this.valueChangeSub();

    }
  }

  @Input() disabled: boolean = false;
  @Input() selectDefault: boolean = false;


  public activeValue: ISelectField = { label: '', value: null } as ISelectField;
  public display: boolean = false;
  public touched: boolean = false;

  form: UntypedFormGroup;



  @ViewChild('selectInput') selectInput: ElementRef;

  @HostListener("document:click", ["$event"])
  public onClick(event: any): void {

    if (this.selectInput) {

      if (!this.selectInput.nativeElement.contains(event.target)) {
        this.hideOptions();
      }


    }
  }

  @Output() activarCampos = new EventEmitter<boolean>();

  constructor(@Optional() private formGroup: FormGroupDirective) {
  }


  ngOnInit(): void {

    this.form = this.formGroup.control;

  }

  ngAfterViewChecked(): void {


  }

  public selectIfOptionIsOnlyOne(): void {
    if (this._options.length === 1 && this.selectDefault) {
      this.setNewValue(this._options[0]);
      this.disabled = true;
    }
  }

  public valueChangeSub(): void {
    if (!this.form) {
      return
    }
    let control = this.form.get(this._selectFieldFormControlName);
    if (control) {
      control.valueChanges.subscribe(r => {
        if (this._options !== undefined && this._options.length > 0) {
          let currentValue = this._options.find(f => f.value === r);
          if (currentValue) {

            this.activeValue = currentValue;
          } else {
            this.activeValue = { label: '', value: null };
          }
        }

      });
    } else {
      console.error('valueChangeSub en icp-selectfield error')
    }
  }

  public resetValue(): void {
    if (this.activeValue.value && this.activeValue.value !== null) {
      this.activeValue = { label: '', value: '' };
      this.setNewValue(this.activeValue);
    }
  }

  public doActiveSelectedOption() {
    if (!this.form) {
      return
    }
    let control = this.form.get(this._selectFieldFormControlName);
    if (!control || !this._options) {
      return;
    } else {

      let currentValue = this._options !== undefined ? this._options.find(f => f.value === control.value) : { label: '', value: '' };
      this.activeValue = currentValue !== undefined ? currentValue : { label: '', value: '' }
    }
  }

  public setNewValue(newValue: ISelectField): void {
    if (!this.form) {
      return
    }
    let control = this.form.get(this._selectFieldFormControlName);
    if (control) {
      this.hideOptions();
      if (control.value == newValue.value) {
        return
      }
      this.activeValue = newValue;
      control.markAsTouched();//Establecer como marcado al hacer click en el componente
      control.patchValue(newValue.value);
      this.activarCampos.emit(true);


    } else {
      console.error(`El selectfield ${this.label} ha fallado.`)
    }

  }

  public activateOptions(): void {
    if (!this.disabled) {
      this.showOptions();
    }
  }

  public showOptions() {
    this.display = true;
    this.touched = true;
  }

  public hideOptions() {
    let control = this.form.get(this._selectFieldFormControlName);
    if (this.touched && control) {
      control.markAsTouched();//Establecer como marcado al hacer click en el componente
    }
    this.display = false;
  }

}
