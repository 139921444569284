import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickStopPropagationDirective } from './stop-propagation.directive';

@NgModule({
  exports: [
    ClickStopPropagationDirective
  ],
  declarations: [
    ClickStopPropagationDirective
  ],
  imports: [
    CommonModule
  ]
})
export class ClickStopPropagationModule { }
