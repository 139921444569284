import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectfieldComponent } from './selectfield.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickStopPropagationModule } from '../../directives/stop-propagation/stop-propagation.module';



@NgModule({
  declarations: [
    SelectfieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickStopPropagationModule
  ],
  exports : [
    SelectfieldComponent
  ]
})
export class SelectfieldModule { }
