export const environment = {
  production: false,
  CRYPTO_KEY: "4SLDKTE235AS65SH",
  CRYPTO_VI: "6DFG6SHFS6SDF2SD", 
  DOMAIN: "https://abanca-int.icp.es:9027/api/I2CS",
  API: {
    GUARDAR_DATOS_WEB: "/Web/GuardarDatosWeb",
    COMPROBAR_TOKEN_CAPTCHA: "/Web/ComprobarCaptcha",
    ENVIAR_DATOS: "/Web/GuardarDatos",
    COMPROBAR_IBAN: "/Web/CheckIBAN",
    AUTHORIZATION: {
        CAPTCHA: '/Authorization/ComprobarCaptcha',
        TOKEN: "/Authorization/Token"
    },
    AREACLIENTESWEB: {
      MIS_POLIZAS: "/AreaClientesWeb/MisPolizas",
      LOGIN: "/AreaClientesWeb/login",
      LOGIN_RECORDAR: "/AreaClientesWeb/loginRecordar",
      LOGIN_CAMBIAR:"/AreaClientesWeb/loginCambiar"
    }

  },
  //RECAPTCHA ENTORNO PROD
  // recaptcha: {
  //   siteKey: "6LfJTTkhAAAAAAa2SxhCaBQ6GxRWFV_RnIkUltsd",
  // },
  //RECAPTCHA ENTORNO TEST
  recaptcha: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
  },
  CREDENTIALS_FAC: {
    "u": "ext.abanca",
    "p": "V7IEn!9E23Xo"
  },
  IH_INICIAL: "H4sIAAAAAAACCqpWykxRsjIy1lHKSVeyUkot1nUNVqoFAAAA//8="
};
