import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'aig-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
@AutoUnsubscribe()
export class LoadingComponent implements OnInit {

  /* isLoading = true; */

  subscription: Subscription;
  isLoading = false;

  constructor(private store: Store<AppState>) {
    this.subscription = this.store.select('loading')
                        .subscribe( loading => {
                          this.isLoading = loading.loading
                        });
   }

  ngOnInit() {}

}
