import { environment } from '@environments/environment';
import * as CryptoJS from 'crypto-js';

export class Utils {

  static obtenerJsonHttpCliente(res: any) {
    if (res.type !== 0) {
      const respuestaJson = res.data;
      return respuestaJson || {};
    } else {
      return {};
    }
  }

  static obtenerPropiedadDeThis(propiedad: string): any {
    let result: any;
    for (const property in this) {
      if (
        this.hasOwnProperty(property) &&
        typeof this[property] === propiedad
      ) {
        result = this[property];
      }


    }
    return result;
  }



  static montarUrl(url: string, items: any[]): string {
    let urlResult = url;

    items.forEach(item => {
      urlResult = urlResult + "/" + item;
    });

    return urlResult;
  }


  static encriptar(dato: string) {
    var key = CryptoJS.enc.Utf8.parse(environment.CRYPTO_KEY); // LA KEY TIENE QUE SER DE 16 DÍGITOS
    var iv = CryptoJS.enc.Utf8.parse(environment.CRYPTO_VI); // EL IV TIENE QUE SER DE 16 DÍGITOS
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dato), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    var p = Utils.Encode(encrypted.toString());
    return p; 
  }

  desencriptar(datoEncriptado: string){
    var key = CryptoJS.enc.Utf8.parse(environment.CRYPTO_KEY); // LA KEY TIENE QUE SER DE 16 DÍGITOS
    var iv = CryptoJS.enc.Utf8.parse(environment.CRYPTO_VI); // EL IV TIENE QUE SER DE 16 DÍGITOS
    var p = Utils.Decode(datoEncriptado);
    var decrypted = CryptoJS.AES.decrypt(p, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    var stringify = CryptoJS.enc.Base64.stringify(decrypted)
    var b64 = CryptoJS.enc.Base64.parse(stringify)
    var textoDesencriptado = CryptoJS.enc.Utf8.stringify(b64);
    return textoDesencriptado;
  }

  static Encode(cadenaCaracteres: string, caracter = 'Y') {
    const codificado = btoa(cadenaCaracteres);
    return [codificado.slice(0, 2), caracter, codificado.slice(2)].join('');
  }

  static Decode(cadenaCaracteres: string) {
    const desOfuscado = [
      cadenaCaracteres.slice(0, 2),
      cadenaCaracteres.slice(3)
    ].join('');
    return window.atob(desOfuscado);
  }

}